import Link from 'next/link';
import { useState } from 'react';
import Marquee from 'react-fast-marquee';

import Button from '@/components/global/Button';
import { usePaths } from '@/lib/paths';
import FontLoaderWrapper from '@/storyblok/components/FontLoaderWrapper';

import styles from './TypefaceMarquee.module.scss';

const Card = ({ card, tabIndex }) => {
  const paths = usePaths();
  const { colorPrimary, colorBackground } = card;
  const fontName = card.font[0]?.fontName;

  const _styles = {
    ['--color-primary']: colorPrimary || 'black',
    ['--color-background']: colorBackground || 'white',
    ['--font']: fontName
  } as React.CSSProperties;

  const slug = `${card.fontName}`.toLowerCase().split(' ').join('-');

  return (
    <Link href={paths.typefaces._slug(slug).$url()} passHref>
      <a className={styles.tag} tabIndex={tabIndex}>
        <div style={_styles} className={styles.card}>
          <span className={styles.fontName}>{card.fontName}</span>
        </div>
      </a>
    </Link>
  );
};

const TypefaceMarquee = ({
  cards,
  direction = 'left',
  speed = 20
}: {
  cards: any[];
  direction: 'left' | 'right';
  speed: number;
}) => {
  const [running, setRunning] = useState(true);
  // We double the cards when there are fewer of them to ensure there are no gaps in the marquee
  const cardsMap = cards.length < 8 ? [...cards, ...cards] : cards;

  return (
    <div className={running ? styles.marqueeRunning : styles.marqueePaused}>
      <div className={styles.controlButton}>
        <Button
          type="button"
          callBack={() => {
            setRunning(!running);
          }}
        >
          {running ? 'Pause' : 'Play'}
        </Button>
      </div>

      {cardsMap.map((card, index) => {
        const [cardFont] = card.font;

        if (!cardFont) return;

        return <FontLoaderWrapper key={`${cardFont._uid}-${index}`} blok={cardFont} />;
      })}

      <Marquee pauseOnHover gradient={false} direction={direction} speed={speed} play={running}>
        <ul className={styles.marquee__list}>
          {cardsMap.map((card, index) => (
            <li key={`${card._uid}-${index}`} className={styles.marquee__listItem}>
              <Card card={card} tabIndex={running ? '-1' : '0'} />
            </li>
          ))}
        </ul>
      </Marquee>
    </div>
  );
};

export default TypefaceMarquee;
